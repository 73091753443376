import React from 'react';
import { Text, Center, VStack, Avatar, Heading } from '@chakra-ui/react';

interface Props {
    imageSrc: string;
    title: string;
}

const AvatarInfo: React.FC<Props> = ({ imageSrc, title }) => {
    return (
        <Center h='100%'>
            <VStack justifyContent='space-between'>
                <Avatar
                    src={imageSrc}
                    boxSize={{ base: '100px', md: '150px' }}
                    name='Avatar profile image'
                />
                <Heading
                    fontSize={{ base: '2xl', md: '3xl', lg: '4xl' }}
                    color='white'
                >
                    {title}
                </Heading>
                <Text
                    fontSize={{ base: 'lg', md: 'xl', lg: '2xl' }}
                    color='white'
                >
                    By Recourse AI
                </Text>
            </VStack>
        </Center>
    );
};

export default AvatarInfo;
