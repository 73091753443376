import React from 'react';
import { Center, Grid, GridItem } from '@chakra-ui/react';
import ControlToggle from '../../molecules/ControlToggle/ControlToggle';
import TalkButton from '../../molecules/TalkButton/TalkButton';
import Captions from '../../atoms/Captions/Captions';
import { useInteractionSetting } from '../../stores/';
import { useInteractionContext } from '../../utils/interaction/InteractionContext';
import { getLastUserMessage } from '../../utils/interaction/utils';
import {
    AudioSetting,
    CaptionSetting,
} from '../../stores/interaction-settings';

export default function AvatarMobileTemplate() {
    const { isCaptionsOn } = useInteractionSetting<CaptionSetting>('captions');
    const { isAudioOn, toggleAudio } = useInteractionSetting<AudioSetting>(
        'audio',
    );

    const { messages } = useInteractionContext();
    const captions = getLastUserMessage(messages);

    return (
        <Grid
            h='100%'
            w='100%'
            templateColumns='repeat(3, 1fr)'
            templateRows='repeat(5, 1fr)'
            position='fixed'
            bottom='0px'
            pb='20px'
            zIndex={10}
        >
            <GridItem colSpan={3} rowSpan={3} /> {/* Empty slot */}
            <GridItem colSpan={3}>
                <Center h='100%' alignItems='flex-end'>
                    {isCaptionsOn && <Captions captions={captions} />}
                </Center>
            </GridItem>
            <GridItem /> {/* Empty slot */}
            <GridItem>
                <Center h='100%' alignItems='flex-end'>
                    <TalkButton />
                </Center>
            </GridItem>
            <GridItem>
                <Center h='100%' alignItems='flex-end'>
                    <ControlToggle
                        icon={isAudioOn ? 'audioOn' : 'audioOff'}
                        ariaLabel='Audio toggle button'
                        tooltipLabel={
                            isAudioOn ? 'Turn audio off' : 'Turn audio on'
                        }
                        onClick={() => toggleAudio()}
                    />
                </Center>
            </GridItem>
        </Grid>
    );
}
