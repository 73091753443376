import { create } from 'zustand';

export type AudioSetting = {
    isAudioOn: boolean;
    toggleAudio: () => void;
};

export type CaptionSetting = {
    isCaptionsOn: boolean;
    toggleCaptions: () => void;
};

export type ModeSetting = {
    isTextMode: boolean;
    toggleTextMode: () => void;
    setIsTextMode: (isTextMode: boolean) => void;
};

type InteractionSettingsStore = AudioSetting & CaptionSetting & ModeSetting;

type InteractionSettingStoreMember = keyof InteractionSettingsStore;

type InteractionSetting = 'audio' | 'captions' | 'mode';

const useInteractionSettingsStore = create<InteractionSettingsStore>(set => ({
    isAudioOn: true,
    toggleAudio: () => set(state => ({ isAudioOn: !state.isAudioOn })),

    isCaptionsOn: true,
    toggleCaptions: () => set(state => ({ isCaptionsOn: !state.isCaptionsOn })),

    isTextMode: false,
    toggleTextMode: () => set(state => ({ isTextMode: !state.isTextMode })),
    setIsTextMode: (isTextMode: boolean) => set({ isTextMode }),
}));

// Pretty sure there's a better way to do this, but don't have the time to think of a clearer API
export const useInteractionSetting = <T>(setting: InteractionSetting): T => {
    return useInteractionSettingsStore(state => {
        const keys = Object.keys(state).filter(key =>
            key.toLowerCase().includes(setting),
        ) as InteractionSettingStoreMember[];

        return Object.fromEntries(keys.map(key => [key, state[key]])) as T;
    });
};

export default useInteractionSettingsStore;
