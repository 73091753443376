import React, { Fragment } from 'react';
import {
    Box,
    Heading,
    HStack,
    IconButton,
    Slide,
    Text,
    useTheme,
} from '@chakra-ui/react';
import { Citation } from '..';
import { Icon } from '../../atoms';
import CitationButton from '../../atoms/CitationButton/CitationButton';
import useStyling from '../../theme/useStyling';

interface Props {
    isSourcesDrawerOpen: boolean;
    onClose: () => void;
    currentSource: Citation | undefined;
}

export default function SourcesSidePanel({
    isSourcesDrawerOpen,
    onClose,
    currentSource,
}: Props) {
    const theme = useTheme();
    const [sourcesSidePanelWidth] = useStyling();

    return (
        <Slide
            direction='right'
            in={isSourcesDrawerOpen}
            style={{
                zIndex: 30,
                width: sourcesSidePanelWidth,
            }}
        >
            <Box h='100%' backgroundColor='dark.600' p='20px' color='white'>
                <HStack justifyContent='space-between'>
                    <Heading color='white'>Sources</Heading>
                    <IconButton
                        aria-label='Close sources side panel'
                        backgroundColor='transparent'
                        _hover={{ backgroundColor: 'transparent' }}
                        size='sm'
                        onClick={onClose}
                    >
                        <Icon
                            iconChoice='x'
                            fontAwesomeProps={{
                                color: theme.colors.dark['400'],
                            }}
                        />
                    </IconButton>
                </HStack>
                {currentSource && (
                    <Fragment>
                        <HStack mt='20px' alignItems='flex-start' spacing='5px'>
                            <Box>
                                <CitationButton citationId={currentSource.id} />
                            </Box>
                            <Box>
                                <Text m='0px' fontWeight={800}>
                                    {currentSource.title}
                                </Text>
                                <Text m='0px'>{currentSource.content}</Text>
                                <Text
                                    m='0px'
                                    textAlign='right'
                                    fontStyle='italic'
                                    fontSize='sm'
                                    color='dark.400'
                                >
                                    {currentSource.reference}
                                </Text>
                            </Box>
                        </HStack>
                    </Fragment>
                )}
            </Box>
        </Slide>
    );
}
