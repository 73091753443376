export const entryToTextMessage = (entry: Entry) => {
    if (entry.user_action && entry.user_action.action_type === 'utterance') {
        return {
            type: 'user',
            actionId: entry.user_action.id,
            text: entry.user_action.payload.text,
        };
    } else if (
        entry.agent_action &&
        entry.agent_action.action_type === 'utterance'
    ) {
        if (entry.agent_action.payload.textual) {
            return {
                type: 'agent',
                actionId: entry.agent_action.id,
                text: entry.agent_action.payload.textual.text,
                attachments: entry.agent_action.payload.media?.attachments,
                className: entry.agent_action.payload.textual.hidden
                    ? 'font-italic'
                    : undefined,
            };
        }
    }
};
export const parseChatEntries = (entries: Entry[]): TextMessage[] => {
    const sortedInteractionMessages = entries.sort((a, b) => {
        const dateA = new Date(a.created_at);
        const dateB = new Date(b.created_at);
        return dateA.getTime() - dateB.getTime();
    });

    return sortedInteractionMessages
        .map(entryToTextMessage)
        .filter(m => m !== undefined) as TextMessage[];
};

export const getLastUserMessage = (messages: TextMessage[]) => {
    const agentMessages = messages.filter(message => message.type === 'agent');

    if (agentMessages.length > 0) {
        return agentMessages[agentMessages.length - 1];
    }
};
