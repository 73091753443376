import React, { createContext, useContext } from 'react';
import useInteraction, { InitProps } from './useInteraction';

type InteractionContextType = ReturnType<typeof useInteraction>;

const InteractionContext = createContext<InteractionContextType | null>(null);

export const InteractionsContextProvider = ({
    children,
    ...initProps
}: React.PropsWithChildren<InitProps>) => {
    const interaction = useInteraction(initProps);
    return (
        <InteractionContext.Provider value={interaction}>
            {children}
        </InteractionContext.Provider>
    );
};

export const useInteractionContext = () => {
    const state = useContext(InteractionContext);
    if (state === null) {
        throw new Error('Missing InteractionContext.Provider in the tree');
    }
    return state;
};
