export type AgentTextMessage = {
    type: 'agent';
    actionId: number;
    text: string;
    attachments?: MediaAttachment[];
    className?: string;
};

export type UserTextMessage = {
    type: 'user';
    actionId: number;
    text: string;
};

export type TextMessage = UserTextMessage | AgentTextMessage;

// Specific to dialogue with choices
export interface DialogueChoice {
    text: string;
    keyphrases: string[];
}

export interface ResponseFeedback {
    positive?: string;
    negative?: string;
}

// General purpose
export interface TextualAction {
    text: string;
    options?: DialogueChoice[];
    feedback?: ResponseFeedback;
    hidden?: boolean;
}

export interface AuditoryAction {
    url: string;
    ssml?: string;
}

export interface BehaviouralAction {
    entry_states: BehaviouralState[];
    exit_states: BehaviouralState[];
}

interface BehaviouralState {
    name: string;
    value: boolean;
}

interface MediaAction {
    attachments: MediaAttachment[];
}

export interface MediaAttachment {
    url: string;
    name?: string;
    kind: string;
}

export interface ConceptualAction {
    concepts: string[];
    agent_action: string | null;
    agent_action_data?: any;
}

export interface ActionPayload {
    textual?: TextualAction;
    auditory: AuditoryAction;
    media?: MediaAction;
    conceptual: ConceptualAction;
    behavioural?: BehaviouralAction;
    control?: {
        finished_actions: number[];
    };
}

export interface UserActionPayload {
    meta: {
        message_source: string;
    };
    text: string;
}

export interface AgentAction {
    id: number;
    action_type: string;
    payload: ActionPayload;
    partial?: boolean;
}

export interface UserAction {
    id: number;
    action_type: string;
    payload: UserActionPayload;
}

export interface Entry {
    created_at: string;
    user_action?: UserAction;
    agent_action?: AgentAction;
}

interface RapportLight {
    type: string;
    intensity: number;
    color: string;
    [key: string]: any;
}
export interface RapportAvatarConfig {
    type: 'rapport';
    scene: {
        projectId: string;
        projectToken: string;
        aiUserId: string;
        lobbyZoneId: string;
    };
    speechRecognition: {
        api: 'browser' | 'cloud';
        mode: 'continuous' | 'push-to-talk';
    };
    animationController: {
        base?: {
            mood?: 'positive' | 'acknowledge' | 'neutral' | 'negative';
            scale?: number;
        };
        talking?: {
            mood?: 'positive' | 'acknowledge' | 'neutral' | 'negative';
            scale?: number;
        };
    };
    lights?: RapportLight[];
}

export interface InteractionStage {
    id: number;
    name: string;
    background_id?: string;
    avatar_img?: string;
    avatar_id?: string;
    hint: string | null;
    unity_version: 'v2019' | 'v2020' | 'v2020_vr';
    unity_build_url: string | null;
    physical_examination_body_map?: any;
    enable_text_aid?: boolean;
    avatar_config?: Record<string, never> | RapportAvatarConfig;
}

export interface Interaction {
    id: number;
    name: string;
    description: string;
    stages: InteractionStage[];
}

export interface ActiveStage {
    id: number;
    interaction_stage: InteractionStage;
    started_at: Date;
    entries: Entry[];
}

export enum UserMessageSource {
    TEXT = 'text',
    DIALOGUE_CHOICE = 'dialogue_choice',
    BROWSER_RECOGNISED_SPEECH = 'browser_recognised_speech',
    CLOUD_RECOGNISED_SPEECH = 'cloud_recognised_speech',
    HINT = 'hint',
}
export interface UserMessageMeta {
    message_source: UserMessageSource;
    [key: string]: string;
}

export type OnSubmitType = (
    messageToSend: string,
    meta: UserMessageMeta,
) => Promise<any>;
