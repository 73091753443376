import React, { useRef } from 'react';
import { Center, InputGroup, IconButton, Text } from '@chakra-ui/react';
import UserInputField from '../../atoms/UserInputField/UserInputField';
import { Icon } from '../../atoms';
import { Form, Formik, FormikHelpers, FormikValues } from 'formik';
import * as Yup from 'yup';

const validationSchema = Yup.object().shape({
    message: Yup.string()
        .required('Message cannot be empty')
        .matches(/^(?!\s*$).+/, 'Message cannot be empty'),
});

interface Props {
    onSubmit: (message: string) => void;
}

const MessageInput: React.FC<Props> = ({ onSubmit }) => {
    const inputRef = useRef<HTMLTextAreaElement>(null);

    const initialValues = {
        message: '',
    };

    const formSubmit = (
        values: FormikValues,
        { resetForm }: FormikHelpers<any>,
    ) => {
        onSubmit(values.message);
        resetForm();
        inputRef.current!.rows = 1;
    };

    return (
        <Formik
            initialValues={initialValues}
            onSubmit={formSubmit}
            validationSchema={validationSchema}
        >
            {({ values, errors, handleChange, handleSubmit }): JSX.Element => (
                <Form
                    style={{
                        width: '100%',
                    }}
                >
                    <InputGroup
                        backgroundColor='dark.700'
                        border='1px solid'
                        borderColor='dark.500'
                        borderRadius='10px'
                        p='10px'
                    >
                        <Center w='100%'>
                            <UserInputField
                                inputRef={inputRef}
                                value={values.message}
                                handleChange={handleChange}
                                handleKeyDown={e => {
                                    if (e.key === 'Enter') {
                                        e.preventDefault();
                                        handleSubmit();
                                    }
                                }}
                            />
                        </Center>
                        <Center>
                            <IconButton
                                aria-label='Send message'
                                rounded='full'
                                type='submit'
                                disabled={values.message === ''}
                            >
                                <Icon iconChoice='arrowUp' />
                            </IconButton>
                        </Center>
                    </InputGroup>
                    <Text color='red.500'>{errors.message}</Text>
                </Form>
            )}
        </Formik>
    );
};

export default MessageInput;
