export default class IntervalStream<T> {
    // Streams each items evenly spaced in time
    // e.g. you could consume the word 'hello' letter by letter with a 2 second wait between each letter

    private buffer: T[] = [];
    private callback?: (item: T) => void;
    private onEndCb?: () => void;
    private intervalId?: number;
    private isRunning = false;
    private isComplete = false;
    private emitInterval: number;

    constructor(emitInterval: number) {
        this.emitInterval = emitInterval;
    }

    public put(...items: T[]): void {
        if (this.isComplete) {
            console.warn('Ignoring new items in a closed staggered buffer');
            return;
        }
        this.buffer = this.buffer.concat(items);
    }

    public setCallback(cb: (str: T) => void): void {
        this.callback = cb;
    }

    public setOnEnd(onEnd: () => void): void {
        this.onEndCb = onEnd;
    }

    public size(): number {
        return this.buffer.length;
    }
    public isEmpty(): boolean {
        return this.size() === 0;
    }

    public emitItem(): T | undefined {
        const emittedString = this.buffer.shift();
        if (emittedString !== undefined && this.callback) {
            this.callback(emittedString);
        }
        return emittedString;
    }

    public start(): void {
        if (this.isRunning) return;

        this.isRunning = true;
        this.intervalId = window.setInterval(() => {
            if (this.isEmpty()) {
                if (this.isComplete) {
                    this.stop();
                }
                // else keep running, but do nothing - i.e. paused state
            } else {
                this.emitItem();
            }
        }, this.emitInterval);
    }

    public stop(): void {
        if (this.isRunning) {
            clearInterval(this.intervalId);
            this.isRunning = false;
            this.intervalId = undefined;

            console.log('Calling interval on end', this.buffer);
            this.onEndCb && this.onEndCb();
        }
    }

    public setCompleted(isComplete: boolean): void {
        this.isComplete = isComplete;
    }
}
