import React, { useCallback, useRef } from 'react';
import IntervalStream from '../utils/IntervalStream';

const useTypewriterEffect = (
    charPrintInterval: number,
    setMessages: React.Dispatch<React.SetStateAction<TextMessage[]>>,
) => {
    const agentMessageBufferMap = useRef(
        new Map<number, IntervalStream<string>>(),
    );

    const displayTextual = useCallback(
        (
            textual: { text: string; hidden?: boolean },
            actionId: number,
            continuous = false,
            attachments: MediaAttachment[] = [],
            onEnd?: () => void,
        ): void => {
            const addActionMessage = (
                messages: TextMessage[],
                text: string,
                actionId: number,
                skipIfAlreadyExists = false,
            ): TextMessage[] => {
                // Callback setter for messages
                const newMessages = [...messages];
                const relevantMessageIndex = newMessages.findIndex(
                    m => m.type === 'agent' && m.actionId === actionId,
                );
                if (skipIfAlreadyExists && relevantMessageIndex !== -1)
                    return newMessages;

                if (relevantMessageIndex !== -1) {
                    newMessages[relevantMessageIndex].text += text;
                } else {
                    newMessages.push({
                        type: 'agent',
                        actionId,
                        text: text,
                        attachments,
                        className: textual.hidden ? 'font-italic' : undefined,
                    });
                }
                return newMessages;
            };

            if (continuous && !textual.hidden) {
                // setIsTyping(true);
                let intervalStream;
                if (!agentMessageBufferMap.current.has(actionId)) {
                    intervalStream = new IntervalStream<string>(
                        charPrintInterval,
                    );
                    // act(InteractionActions.process);
                    agentMessageBufferMap.current.set(actionId, intervalStream);
                    intervalStream.setCallback(nextChar => {
                        setMessages(messages =>
                            addActionMessage(messages, nextChar, actionId),
                        );
                    });

                    if (onEnd) {
                        intervalStream.setOnEnd(onEnd);
                    }

                    intervalStream!.start();
                } else {
                    intervalStream = agentMessageBufferMap.current.get(
                        actionId,
                    );
                }
                intervalStream!.put(...textual.text, ' ');
            } else {
                // We don't send the process action for single text
                // as there is no delay for making the update
                // act(InteractionActions.process);
                setMessages(messages =>
                    addActionMessage(messages, textual.text, actionId, true),
                );
                onEnd && onEnd();
            }
        },
        [charPrintInterval, setMessages],
    );

    const complete = useCallback((actionId: number) => {
        agentMessageBufferMap.current.get(actionId)?.setCompleted(true);
    }, []);

    const stop = useCallback((actionId: number) => {
        agentMessageBufferMap.current.get(actionId)?.stop();
    }, []);

    const stopAll = useCallback(() => {
        Array.from(agentMessageBufferMap.current.values()).forEach(
            messageBuffer => {
                messageBuffer.stop();
                messageBuffer.setCompleted(true);
            },
        );
        console.log(agentMessageBufferMap.current);
    }, []);

    return { displayTextual, complete, stop, stopAll };
};

export default useTypewriterEffect;
