import {
    ActiveStage,
    AgentAction,
    UserMessageMeta,
} from '../../../../../apps/mooc-frontend/src/components/activities/consultation/components/types';
import { useCallback, useEffect, useRef, useState } from 'react';
import useAsyncError from '../../../../core/src/hooks/useAsyncError';
import useQueue from '../../../../core/src/hooks/useQueue';
import useTypewriterEffect from '../../../../core/src/hooks/useTypewriterEffect';
import {
    ChatConnection,
    ChatConnOptions,
    ChatWebsocketCodes,
    openChatHttpConn,
    openChatWebsocketConn,
} from './InteractionConnection';
import { parseChatEntries } from './utils';
import { Session } from '../../../../../apps/mooc-frontend/src/components/activities/ActivityContent';
import ExerciseAPI from '../../../../../apps/mooc-frontend/src/components/activities/ExerciseAPI';
import { promiseWithResolvers } from '../../../../core/src/utils/promise';
import { InteractionActions, useInteractionAgent } from './useInteractionAgent';

const hintsData = [
    'What causes sudden, unexplained dizziness?',
    'How can I manage frequent headaches?',
    'What are common treatments for insomnia?',
    'Why do I experience joint pain after exercise?',
    'Is my diet affecting my energy levels?',
    'What are the symptoms of vitamin deficiency?',
    'How can I improve my digestion naturally?',
];

interface RunnableAction {
    // Resolves when an action has finished processing
    promise: Promise<any>;
    // Will immediately terminate a running action
    stop: () => void;
    // Will signal that a multipart can finish when it runs out of content
    // (i.e. the last part of a multipart action has been sent for processing)
    complete: () => void;
}

export interface InitProps {
    session: Session;
    exerciseAPI: ExerciseAPI;
    useStreaming: boolean;
}

const useInteraction = ({ session, exerciseAPI, useStreaming }: InitProps) => {
    const sessionId = session.id;
    const stageId = session.active_stage.id;
    const activeStage = session.active_stage;

    const chatUrl = `sessions/${sessionId}/stages/${stageId}/`;

    const throwAsyncError = useAsyncError();
    const [messages, setMessages, addMessages, clearMessages] = useQueue<
        TextMessage
    >();

    const [hints, setHints] = useState(() => {
        // TODO get from API
        const shuffledHints = hintsData.sort(() => 0.5 - Math.random());
        return shuffledHints.slice(0, 4);
    });

    const { act, ...agentState } = useInteractionAgent();

    const {
        displayTextual,
        complete: completeTextual,
        stop: stopTextual,
    } = useTypewriterEffect(40, setMessages);

    const chatConnectionRef = useRef<ChatConnection | null>(null);
    const avatarRef = useRef(null);
    const runningActions = useRef(new Map<any, RunnableAction>());
    const interruptedActions = useRef(new Set<any>());

    const runAction: (
        action: AgentAction,
        activeStage: ActiveStage,
    ) => RunnableAction = useCallback(
        (action, activeStage) => {
            const { promise, resolve } = promiseWithResolvers();

            const agentActionTasks: RunnableAction['promise'][] = [];
            const stopFunctions: RunnableAction['stop'][] = [];
            const completeFunctions: RunnableAction['complete'][] = [];

            const stop = () => stopFunctions.forEach(f => f());
            const complete = () => completeFunctions.forEach(f => f());

            const { payload } = action;

            const { auditory, behavioural, textual } = payload;

            const hasAudio = auditory && auditory.url !== 'none';
            const usesRapportTTS =
                activeStage?.interaction_stage.avatar_config?.type ===
                    'rapport' && textual?.text;

            if ((hasAudio || usesRapportTTS) && avatarRef.current) {
                const audioPromise = promiseWithResolvers();
                avatarRef.current.speak(
                    auditory.url,
                    textual?.text,
                    auditory.ssml,
                    behavioural,
                    action.id,
                    () => audioPromise.resolve(),
                );

                agentActionTasks.push(audioPromise.promise);
                stopFunctions.push(() => {
                    avatarRef.current!.interrupt(action.id);
                });
                completeFunctions.push(() => {
                    avatarRef.current!.completeSpeech(action.id);
                });
            }

            if (textual?.text) {
                const textTaskPromise = promiseWithResolvers();
                displayTextual(
                    textual,
                    action.id,
                    action.partial,
                    payload.media?.attachments,
                    () => textTaskPromise.resolve(),
                );

                agentActionTasks.push(textTaskPromise.promise);
                stopFunctions.push(() => stopTextual(action.id));
                completeFunctions.push(() => completeTextual(action.id));
            }

            Promise.all(agentActionTasks).then(() => resolve());
            return { promise, stop, complete };
        },
        [completeTextual, displayTextual, stopTextual],
    );

    useEffect(() => {
        act(InteractionActions.initialise);
        const chatMessages = parseChatEntries(activeStage.entries);
        setMessages(chatMessages);

        const connOptions: ChatConnOptions = {
            chatUrl,
            activeStage: activeStage,
            act,
            sessionData: session,
            avatarRef,
            exerciseAPI: exerciseAPI,
            setActions: actions => {
                actions.forEach(action => {
                    if (interruptedActions.current.has(action.id)) {
                        return;
                    }

                    const { payload } = action;
                    const isFinal = !action.partial;
                    const hasFinishedActions = !!payload.control
                        ?.finished_actions.length;
                    const isProcessingRequired = payload.textual?.text;

                    // The below handles an edge case to this, if we don't receive any
                    // speak action, and want to early exit the processing state, usually
                    // at the start of interactions, the below conditions will take care of it
                    if (
                        !isProcessingRequired &&
                        !hasFinishedActions &&
                        isFinal
                    ) {
                        act(InteractionActions.processed);
                        return;
                    }

                    if (payload.control?.finished_actions.length) {
                        payload.control.finished_actions.forEach(id => {
                            runningActions.current.get(id)?.complete();
                        });
                        return;
                    }

                    if (action.id) {
                        act(InteractionActions.process);
                        const runningAction = runAction(action, activeStage);

                        if (isFinal) {
                            runningAction.complete();
                        }

                        if (!runningActions.current.has(action.id)) {
                            runningActions.current.set(
                                action.id,
                                runningAction,
                            );
                            runningAction.promise.finally(() => {
                                act(InteractionActions.processed);
                                runningActions.current.delete(action.id);
                            });
                        }
                    }
                });
            },
            onSuccess: connection => (chatConnectionRef.current = connection),
            onError: console.log,
        };

        const openConn = useStreaming
            ? openChatWebsocketConn
            : openChatHttpConn;

        openConn(connOptions);

        return () => {
            chatConnectionRef.current?.close(ChatWebsocketCodes.CLOSE_NORMAL);
        };
    }, [
        runAction,
        chatUrl,
        exerciseAPI,
        setMessages,
        throwAsyncError,
        useStreaming,
        activeStage,
        session,
        act,
    ]);

    const interrupt = useCallback(() => {
        Array.from(runningActions.current.entries()).forEach(
            ([id, runningAction]) => {
                interruptedActions.current.add(id);
                runningAction.stop();
            },
        );
    }, []);

    const sendMessage = useCallback(
        (message: string, meta: UserMessageMeta) => {
            interrupt();

            addMessages({
                type: 'user',
                text: message,
                actionId: new Date().getTime(),
            });

            const requestData = {
                action_type: 'utterance',
                payload: {
                    text: message,
                    meta,
                },
                skip_tts_synthesis: true,
            };
            chatConnectionRef.current?.send(JSON.stringify(requestData));
            act(InteractionActions.send);
        },
        [act, addMessages, interrupt],
    );

    return {
        hints,
        messages,
        activeStage,
        interrupt,
        sendMessage,
        agentState,
    };
};
export default useInteraction;
