import React, { useMemo, useState } from 'react';
import {
    AvatarTemplate,
    Navbar,
    SourcesSidePanel,
    TextTemplate,
} from '@recourseai/components';
import { useQuery } from '@tanstack/react-query';
import { moocAPI } from '../services';
import useAsyncError from '../hooks/useAsyncError';
import { useParams } from 'react-router-dom';
import ExerciseAPI from '../components/activities/ExerciseAPI';
import { Session } from '../components/activities/ActivityContent';
import LoadingSpinner from '../components/generic/LoadingSpinner';
import { InteractionsContextProvider } from '@recourseai/components/src/utils/interaction/InteractionContext';
import { Citation } from '@recourseai/core/src/types';
import { Center } from '@chakra-ui/react';
import { useInteractionSetting } from '@recourseai/components/src/stores';
import { ModeSetting } from '@recourseai/components/src/stores/interaction-settings';

const createOrGetActiveAttempt = (
    activityId: string,
    courseId: string,
    exerciseSessionId?: string,
): Promise<any> => {
    return moocAPI.post(`course/${courseId}/activity/${activityId}/attempt/`, {
        exercise_session_id: exerciseSessionId,
    });
};

const Consultation = () => {
    const throwAsyncError = useAsyncError();

    const { courseId, activityId } = useParams<{
        courseId: string;
        activityId: string;
    }>();

    const { data: activity } = useQuery<Activity>(
        ['course', courseId, 'activity', activityId],
        () => moocAPI.get(`course/${courseId}/activities/${activityId}/`),
        {
            onError: throwAsyncError,
        },
    );

    const exerciseAPI = useMemo(
        () => (activity ? new ExerciseAPI(activity) : undefined),
        [activity],
    );

    const { data: session } = useQuery<Session>(
        ['interaction', activity?.config.exercise_api_route, 'session'],
        () => {
            const attempts = activity?.student_activity?.attempts || [];
            const existingSessionId = attempts.find(
                attempt => attempt.completed_datetime === null,
            )?.exercise_session_id;

            if (existingSessionId) {
                return exerciseAPI!.sessionDetails(existingSessionId);
            } else {
                return exerciseAPI!
                    .startSession()
                    .then(({ id }) =>
                        Promise.all([
                            exerciseAPI!.sessionDetails(id),
                            createOrGetActiveAttempt(activityId, courseId, id),
                        ]),
                    )
                    .then(([sessionDetails]) => sessionDetails);
            }
        },
        {
            enabled: !!exerciseAPI,
            onError: throwAsyncError,
        },
    );

    const { isTextMode } = useInteractionSetting<ModeSetting>('mode');

    const [isSourcesDrawerOpen, setIsSourcesDrawerOpen] = useState(false);
    const [currentSource, setCurrentSource] = useState<Citation>();

    const onCitationClick = (citation: Citation) => {
        setIsSourcesDrawerOpen(true);
        setCurrentSource(citation);
    };

    if (!activity || !session) {
        return (
            <Center h='100%'>
                <LoadingSpinner />
            </Center>
        );
    }

    return (
        <InteractionsContextProvider
            session={session}
            useStreaming={activity!.config.use_streaming}
            exerciseAPI={exerciseAPI!}
        >
            <Navbar title={activity.title} />
            <SourcesSidePanel
                isSourcesDrawerOpen={isSourcesDrawerOpen}
                onClose={() => {
                    setIsSourcesDrawerOpen(false);
                }}
                currentSource={currentSource}
            />
            {!isTextMode && (
                <AvatarTemplate
                    title={activity.title}
                    onCitationClick={onCitationClick}
                />
            )}
            {isTextMode && (
                <TextTemplate
                    title={activity.title}
                    onCitationClick={onCitationClick}
                />
            )}
        </InteractionsContextProvider>
    );
};

export default Consultation;
