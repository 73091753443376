import React, { Fragment, useEffect, useRef } from 'react';
import {
    Box,
    Flex,
    HStack,
    useBreakpointValue,
    VStack,
} from '@chakra-ui/react';
import AvatarInfo from '../../molecules/AvatarInfo/AvatarInfo';
import ChatHistory from '../../molecules/ChatHistory/ChatHistory';
import MessageInput from '../../organisms/MessageInput/MessageInput';
import { UserMessageSource } from '../../../../../apps/mooc-frontend/src/components/activities/consultation/components/types';
import { useInteractionContext } from '../../utils/interaction/InteractionContext';
import { getChatStyles, scrollBarStyles } from '../../theme/consts';
import Hint from '../../atoms/Hint/Hint';
import { isDesktop } from 'react-device-detect';
import { Citation } from '../../../../core/src/types';
import { useInteractionSetting } from '../../stores';
import { ModeSetting } from '../../stores/interaction-settings';

export interface Props {
    title: string;
    showChatHistory?: boolean;
    onCitationClick: (citation: Citation) => void;
}

const Chat = ({ title, showChatHistory = true, onCitationClick }: Props) => {
    const { messages, sendMessage, hints } = useInteractionContext();

    const containerRef = useRef<HTMLDivElement>(null);

    const isLargeDesktop = useBreakpointValue({ '2xl': true });

    const { isTextMode } = useInteractionSetting<ModeSetting>('mode');
    const styles = getChatStyles(isTextMode);

    // temporary image till API implementation
    const avatarImageSrc =
        'https://static.vecteezy.com/system/resources/thumbnails/002/002/403/small/man-with-beard-avatar-character-isolated-icon-free-vector.jpg';

    // Limit the number of hints when the space is limited so that there is no overflow
    const displayHints = hints.slice(
        0,
        isDesktop && (!isTextMode || !isLargeDesktop) ? 3 : hints.length,
    );

    useEffect(() => {
        if (containerRef.current) {
            containerRef.current.scrollTop =
                containerRef.current.scrollHeight + 10;
        }
    }, [messages]);

    return (
        <Flex direction='column' {...styles.chatContainer}>
            <Box
                flex='8'
                overflowY='auto'
                ref={containerRef}
                sx={scrollBarStyles}
            >
                {messages.length > 0 ? (
                    <Fragment>
                        {showChatHistory && (
                            <ChatHistory
                                messages={messages}
                                avatarImageSrc={avatarImageSrc}
                                isTextMode={isTextMode}
                                onCitationClick={onCitationClick}
                            />
                        )}
                    </Fragment>
                ) : (
                    <AvatarInfo imageSrc={avatarImageSrc} title={title} />
                )}
            </Box>
            <VStack flex='1' mx='auto' {...styles.historyContainer}>
                <HStack
                    w='100%'
                    mt='25px'
                    overflowX='auto'
                    justifyContent={
                        isTextMode ? { base: 'start', md: 'center' } : 'center'
                    }
                >
                    {displayHints.map(hint => (
                        <Hint
                            key={hint}
                            text={hint}
                            onClick={hint => {
                                sendMessage(hint, {
                                    message_source: UserMessageSource.HINT,
                                });
                            }}
                        />
                    ))}
                </HStack>
                <MessageInput
                    onSubmit={message =>
                        sendMessage(message, {
                            message_source: UserMessageSource.TEXT,
                        })
                    }
                />
            </VStack>
        </Flex>
    );
};

export default Chat;
